module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/android-chrome-256x256.png","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-256x256.png","sizes":"256x256","type":"image/png"}],"name":"Onfire Solutions AB","short_name":"Onfire","start_url":"/","theme_color":"#ffffff","background_color":"#ffffff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0afa88d54f83da46cb7e6541760878bc"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"Montserrat","file":"https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
